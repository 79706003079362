import React, { useEffect, useState } from 'react';

import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Row, Col } from 'react-bootstrap';

import ProfileLayout from 'components/layouts/profile/profile.layout';
import { useDispatch, useSelector } from 'react-redux';
import { accountMessagesSelector, accountProfileSelector } from 'state/account/account.selectors';
import {
    accountFetchAskThePharmComposeMessageRoutine,
    accountFetchAskThePharmMessagesRoutine,
    accountFetchContactUsComposeMessageRoutine,
    accountFetchContactUsMessagesRoutine,
    accountPostAskThePharmComposeMessageRoutine,
    accountPostContactUsComposeMessageRoutine
} from 'state/account/account.routines';
import Button from 'ui-kit/button/button';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';

import './messages.style.scss';
import { Field, Form, Formik } from 'formik';
import Text from 'ui-kit/text/text';
import TextArea from 'ui-kit/text/textarea';
import FormSelect from 'ui-kit/form-select/form-select';
import { Messages } from 'types/messages';
import { MESSAGE_SCHEMA } from 'schema/messages';
import { messageOptions } from 'const/options';
import BirdiModalContent from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import { navigate } from 'gatsby';
import { toNumber } from 'lodash';

const MessageForm = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const messages = useSelector(accountMessagesSelector);
    const profileObject = useSelector(accountProfileSelector);
    const formName = 'MessageForm';

    const handleMessagesFormCancel = () => {
        dispatch(closeModal({}));
    };

    const handleFormSubmit = (values: Messages) => {
        const submissionCallbacks = {
            onSuccess: () => {
                dispatch(
                    openModal({
                        showClose: true,
                        bodyContent: (
                            <BirdiModalContent
                                icon={'success'}
                                title={t(`pages.profile.messages.callbacks.success`)}
                                body={t(`pages.profile.messages.callbacks.successMessage`)}
                            />
                        ),
                        ctas: [
                            {
                                label: t(`pages.profile.messages.labels.gotIt`),
                                variant: 'primary',
                                onClick: handleMessagesFormCancel
                            }
                        ]
                    })
                );
                dispatch(accountFetchContactUsMessagesRoutine.trigger());
                dispatch(accountFetchAskThePharmMessagesRoutine.trigger());
            },
            onFailure: () => {
                dispatch(
                    openModal({
                        showClose: true,
                        bodyContent: (
                            <BirdiModalContent
                                icon={'alert'}
                                title={t(`pages.profile.messages.callbacks.error`)}
                                body={t(`pages.profile.messages.callbacks.errorMessage`)}
                            />
                        ),
                        ctas: [
                            {
                                label: t(`pages.profile.messages.labels.gotIt`),
                                variant: 'primary',
                                onClick: () => {
                                    dispatch(closeModal({}));
                                }
                            }
                        ]
                    })
                );
                dispatch(accountFetchContactUsMessagesRoutine.trigger());
                dispatch(accountFetchAskThePharmMessagesRoutine.trigger());
            }
        };
        if (values.messageType === 'Contact Us') {
            const messageTemplate = {
                ...messages?.composeMessage.contactUs,
                HeaderID: 1,
                MessageID: 1,
                Subject: values.subject,
                CreateDate: new Date().toISOString(),
                Body: values.message,
                Action: 'NEW',
                WebUserID: profileObject?.epostPatientNum,
                ...submissionCallbacks
            };
            dispatch(
                accountPostContactUsComposeMessageRoutine.trigger({
                    ...messageTemplate
                })
            );
        } else {
            const messageTemplate = {
                ...messages?.composeMessage.askThePharm,
                Subject: values.subject,
                CreateDate: new Date().toISOString(),
                Body: values.message,
                Action: 'NEW',
                WebUserID: profileObject?.epostPatientNum,
                PersonFirstName: profileObject?.patientFirstName,
                PersonLastName: profileObject?.patientLastName,
                Email: profileObject?.patientEmailAddress,
                ...submissionCallbacks
            };
            dispatch(
                accountPostAskThePharmComposeMessageRoutine.trigger({
                    ...messageTemplate
                })
            );
        }
    };
    return (
        <Row>
            <Col className="d-flex flex-column">
                <Formik<Partial<Messages>>
                    onSubmit={(values) => {
                        handleFormSubmit(values);
                    }}
                    validationSchema={MESSAGE_SCHEMA}
                    initialValues={{
                        messageType: '',
                        subject: '',
                        message: ''
                    }}
                >
                    {(formik: any) => (
                        <Form
                            id="messages-form"
                            onSubmit={formik.handleSubmit}
                            autoComplete="off"
                            className="text-left"
                            data-ga-form-name={formName}
                        >
                            <Row>
                                <Col xs={12} md={12} lg={{ span: 6, offset: 3 }}>
                                    <p className="text-center">{t('pages.profile.messages.labels.formInstructions')}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={12} lg={12}>
                                    <Field
                                        name="messageType"
                                        component={FormSelect}
                                        options={messageOptions}
                                        placeholder={t('pages.profile.messages.labels.messageType')}
                                        errors={
                                            formik.errors?.messageType
                                                ? t('forms.errorMessages.requiredField', {
                                                      label: t('pages.profile.messages.labels.messageType')
                                                  })
                                                : undefined
                                        }
                                        touched={formik.touched.messageType}
                                    />
                                </Col>
                            </Row>
                            {formik.values.messageType && (
                                <>
                                    <Row>
                                        <Col>
                                            <p>
                                                {formik.values.messageType === 'Contact Us'
                                                    ? t('pages.profile.messages.labels.toGeneral')
                                                    : t('pages.profile.messages.labels.toPharmacist')}
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Text
                                                label={t('pages.profile.messages.labels.subject')}
                                                name="subject"
                                                onChange={formik.handleChange}
                                                errors={
                                                    formik.errors?.subject
                                                        ? t('forms.errorMessages.requiredField', {
                                                              label: t('pages.profile.messages.labels.subject')
                                                          })
                                                        : undefined
                                                }
                                                touched={formik.touched.subject}
                                                value={formik.values?.subject}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <TextArea
                                                label={t('pages.profile.messages.labels.message')}
                                                name="message"
                                                rows={5}
                                                onChange={formik.handleChange}
                                                errors={
                                                    formik.errors?.message
                                                        ? t('forms.errorMessages.requiredField', {
                                                              label: t('pages.profile.messages.labels.message')
                                                          })
                                                        : undefined
                                                }
                                                touched={formik.touched.message}
                                                value={formik.values?.message}
                                                maxLength={2000}
                                            />
                                        </Col>
                                    </Row>
                                </>
                            )}

                            <Row className={`flex-column mt-3 text-center`}>
                                <Col className={`text-center`}>
                                    <Button
                                        className="sm-full md-full"
                                        label={t('pages.profile.messages.labels.submit')}
                                        variant="primary"
                                        type="submit"
                                        disabled={
                                            !formik.values.subject.length ||
                                            !formik.values.message.length ||
                                            !formik.dirty
                                        }
                                        onClick={formik.handleSubmit}
                                        dataGAFormName={formName}
                                    />
                                </Col>
                                <Col className={`text-center mt-3`}>
                                    <Button
                                        label={t('pages.profile.messages.labels.cancel')}
                                        className="md-full md-pad-y-2"
                                        type="button"
                                        variant="text"
                                        onClick={handleMessagesFormCancel}
                                        dataGAFormName={formName}
                                    />
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </Col>
        </Row>
    );
};

const InboxMessage = ({ message, handleClick }) => {
    const messageDate = new Date(message.Date).toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    });
    const messageType = messageOptions.find((option) => option.value === message.messageSource);

    return (
        <Row className="message">
            <Col xs={{ span: 6, order: 1 }} lg={3} xl={2}>
                <div className={`message--status state-${message.Action.toLowerCase()} text-center mb-2 mb-lg-0`}>
                    {message.Action}
                </div>
            </Col>
            <Col xs={{ span: 6, order: 3 }} lg={{ span: 5, order: 2 }} xl={7}>
                <div className={'message--source text-secondary'}>{messageType.label}</div>
                <div className={'message--subject'}>{message.Subject}</div>
            </Col>
            <Col
                xs={{ span: 6, order: 2 }}
                lg={{ span: 3, order: 3 }}
                xl={2}
                className="d-flex align-items-center justify-content-end"
            >
                <div className={'message--date text-secondary'}>
                    <small>{messageDate}</small>
                </div>
            </Col>
            <Col xs={{ span: 6, order: 4 }} lg={1} xl={1} className="d-flex align-items-center justify-content-end">
                <div className={'message--read-cta'}>
                    <Button
                        label={''}
                        variant={'text-blue'}
                        dataGAFormName={'message-list'}
                        className={'no-min-width'}
                        chevron="right"
                        onClick={handleClick}
                    />
                </div>
            </Col>
        </Row>
    );
};

const MessagesSection = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const messages = useSelector(accountMessagesSelector);
    const profileObject = useSelector(accountProfileSelector);
    const [customScrollPos, setCustomScrollPos] = useState(undefined);
    const askThePharmMessages = messages?.askThePharm;
    const contactUsMessages = messages?.contactUs;
    const combinedMessages =
        askThePharmMessages !== undefined && contactUsMessages !== undefined
            ? [...askThePharmMessages, ...contactUsMessages]
                  .sort((a, b) => {
                      const firstDate = new Date(a.Date);
                      const secondDate = new Date(b.Date);
                      return secondDate.getTime() - firstDate.getTime();
                  })
                  .map((message) => {
                      const messageSource = Object.prototype.hasOwnProperty.call(message, 'PharmReply')
                          ? 'Ask The Pharmacist'
                          : 'Contact Us';
                      return {
                          ...message,
                          messageSource
                      };
                  })
            : undefined;

    const getLatestSavedScrollPosition = (pathname: string): number => {
        if (typeof sessionStorage !== 'undefined') {
            let n = sessionStorage.length;
            let i = 0;
            const partialKey = `@@scroll|${pathname}|`;
            let results = [];
            while (++i < n) {
                const key = sessionStorage.key(i);
                if (key?.includes(partialKey)) {
                    results.push(key);
                }
            }
            if (results.length === 0) {
                return 0;
            }
            results.sort();
            const foundScollPosition = sessionStorage.getItem(results[results.length - 1]);
            return foundScollPosition ? toNumber(foundScollPosition) : 0;
        } else {
            return 0;
        }
    };

    if (customScrollPos === undefined) {
        setCustomScrollPos(getLatestSavedScrollPosition('/secure/profile/messages'));
    }

    useEffect(() => {
        dispatch(accountFetchAskThePharmMessagesRoutine.trigger());
        dispatch(accountFetchContactUsMessagesRoutine.trigger());
        dispatch(accountFetchContactUsComposeMessageRoutine.trigger());
        dispatch(accountFetchAskThePharmComposeMessageRoutine.trigger());
    }, [profileObject]);

    const customScrollPosition = () => {
        if (props?.location?.state?.useCustomScrollPos) {
            if (customScrollPos && customScrollPos < document.body.scrollHeight) {
                window.scrollTo(0, customScrollPos);
            }
        }
    };

    const handleFetchMessageThread = (message: any) => {
        if (typeof window !== 'undefined') {
            message.messageSource === 'Ask The Pharmacist'
                ? navigate(`/secure/profile/messages/ask-pharm?header_id=${message.HeaderID}`)
                : navigate(`/secure/profile/messages/contact-us?header_id=${message.HeaderID}`);
        }
    };

    const composeMessage = () => {
        dispatch(
            openModal({
                showClose: true,
                bodyContent: (
                    <BirdiModalContent
                        icon={'none'}
                        title={t(`pages.profile.messages.composeMessageTitle`)}
                        body={<MessageForm />}
                    />
                ),
                ctas: []
            })
        );
    };

    return (
        <ProfileLayout
            eyebrowText={t(`pages.profile.messages.eyebrowText`)}
            title={t(`pages.profile.messages.title`)}
            heading={t(`pages.profile.messages.heading`)}
        >
            <Button
                label={t(`pages.profile.messages.compose`)}
                className="no-min-width btn-compose-message"
                type="button"
                variant="primary"
                onClick={composeMessage}
            />
            <div className="messages-container">
                {combinedMessages && combinedMessages.length === 0 && (
                    <div className="message--no-messages text-center">
                        <p className="w-75 mx-auto">{t(`pages.profile.messages.noMessages`)}</p>
                        <p className="d-flex justify-content-center mt-5">
                            <small>
                                <Button
                                    variant="text-blue"
                                    label={t(`pages.profile.messages.composeMessage`)}
                                    type="button"
                                    onClick={composeMessage}
                                />
                            </small>
                        </p>
                    </div>
                )}
                {combinedMessages && combinedMessages.length !== 0 && (
                    <>
                        {combinedMessages.map((message) => (
                            <InboxMessage
                                message={message}
                                key={`message-id-${message.HeaderID}`}
                                handleClick={() => handleFetchMessageThread(message)}
                            />
                        ))}
                        {customScrollPosition()}
                    </>
                )}
            </div>
        </ProfileLayout>
    );
};

export default MessagesSection;
