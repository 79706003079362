import React, { useState } from 'react';

import Form from 'react-bootstrap/Form';
import { TrackInputFocus } from 'util/google_optimize/optimize_helper';

import Tooltip from 'ui-kit/tooltip/tooltip';

import { TextProps } from './text.props';

import classNames from 'classnames';

import './text.style.scss';

const TextArea = ({
    className,
    errors,
    footNote,
    icon,
    label,
    name,
    onChange,
    onBlur,
    onFocus,
    showErrorsText = true,
    style,
    touched,
    tooltipText,
    type = 'text',
    value,
    disabled = false,
    maxLength = undefined,
    rows = undefined
}: TextProps) => {
    const [isFocused, setIsFocused] = useState(false);
    const handleOnFocus = (event: React.FocusEvent<HTMLInputElement>) => {
        setIsFocused(true);
        TrackInputFocus(event.target, label);
        onFocus !== undefined && onFocus(event);
    };
    const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        setIsFocused(false);
        onBlur !== undefined && onBlur(event);
    };

    const classes = classNames(
        'text',
        { focused: isFocused },
        { 'has-value': value },
        { 'has-errors': touched && errors }
    );

    return (
        <div className={classes} style={style}>
            <div className={`text-input-container type-textarea`}>
                {label && <Form.Label className="text-label">{label}</Form.Label>}
                <Form.Control
                    as={'textarea'}
                    className={`text-input ${className} ${disabled ? 'disabled' : ''}`}
                    type={type}
                    name={name}
                    onChange={onChange}
                    onFocus={handleOnFocus}
                    onBlur={handleOnBlur}
                    value={value}
                    disabled={disabled}
                    maxLength={maxLength ? maxLength : 128}
                    rows={rows}
                />
                {tooltipText && <Tooltip tip={tooltipText} />}
                {icon}
            </div>
            {touched && errors && showErrorsText && <div className="text-errors">{errors}</div>}
            {footNote && <div className="text-footnote">{footNote}</div>}
        </div>
    );
};

export default TextArea;
